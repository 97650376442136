<template>
    <div>
        <div class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-highlight-100 text-highlight-textLarge border border-highlight-300" v-if="! customerId">
            <span><Icon class="w-6 h-6 mr-2 text-blue-600" name="Info" /></span>
            <span>please select a customer from the top menu.</span>
        </div>
        <div class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-highlight-100 text-highlight-textLarge border border-highlight-300" v-if="! community">
            <span><Icon class="w-6 h-6 mr-2 text-blue-600" name="Info" /></span>
            <span>please close the modal and select a community from the top menu.</span>
        </div>
        <div v-if="filteredWebsites.length">
            <div
                v-for="website in filteredWebsites"
                :key="website.id"
                class="border-b mb-6 pb-6 border-gray-200 property-row"
            >
                <div class="flex justify-between items-center">
                    <div class="text-black font-medium">
                        {{ website.production_url}}
                    </div>
                    <div>
                        <modal-link :route="passFlowTo" :params="{ id: website.id }">
                            <button class="btn-primary">manage site</button>
                        </modal-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="! filteredWebsites.length && (community && customerId) " class="empty-state">
            <div class="es-ill">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476.256 357.755">
                    <g data-name="Group 197">
                        <path fill="#eaf6ff" d="M378.687 46.189c39.267 21.853 81.39 58.595 82.34 95.964.633 37.372-40.222 75.694-79.811 113.7-39.272 38.322-77.594 76.961-132.068 93.43-54.475 16.151-125.101 10.45-159.306-27.872s-31.988-108.948-23.12-171.027S90.787 35.421 125.313 13.568c34.2-21.853 87.1-12.668 132.068-4.434s81.712 15.202 121.306 37.055z" data-name="Path 254"/>
                        <g data-name="Group 196">
                            <g transform="translate(263.758 23.085)">
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" d="M137.805 178.388v3.657H14.535v-3.657a10.11 10.11 0 0110.113-10.116h103.03a10.12 10.12 0 0110.127 10.116z" data-name="Path 235"/>
                                <path stroke="#000" stroke-miterlimit="10" stroke-width="2.136" d="M137.805 182.045a10.129 10.129 0 01-10.128 10.128H24.648a10.12 10.12 0 01-10.116-10.128z" data-name="Path 236"/>
                                <rect width="112.959" height="133.07" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" data-name="Rectangle 981" rx="31.27" transform="translate(19.688)"/>
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M35.155 192.171h81.556v1.138a8.236 8.236 0 01-8.236 8.236H43.391a8.236 8.236 0 01-8.236-8.236v-1.138h0z" data-name="Rectangle 982"/>
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M72.418 201.545h7.031v61.401h-7.031z" data-name="Rectangle 983"/>
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M17.347 136.633v8.9H0v-8.9a8.674 8.674 0 1117.347 0z" data-name="Path 237"/>
                                <path stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M17.347 145.533a8.674 8.674 0 01-17.347 0z" data-name="Path 238"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="5.573" d="M8.673 154.206v15.115c0 4.687 0 12.724 5.862 12.724" data-name="Path 239"/>
                                <path stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M151.864 145.533a8.674 8.674 0 01-17.347 0z" data-name="Path 240"/>
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" d="M151.864 136.633v8.9h-17.348v-8.9a8.674 8.674 0 1117.347 0z" data-name="Path 241"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="5.573" d="M143.194 154.206v15.115c0 4.687 0 12.724-5.862 12.724" data-name="Path 242"/>
                                <g data-name="Group 194" transform="translate(60.682 119.553)">
                                    <ellipse cx="15.488" cy="31.118" data-name="Ellipse 115" rx="15.488" ry="31.118"/>
                                    <ellipse cx="15.488" cy="31.118" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2.25" data-name="Ellipse 116" rx="15.488" ry="31.118"/>
                                </g>
                                <circle cx="5.156" cy="5.156" r="5.156" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" data-name="Ellipse 117" transform="translate(14.535 289.793)"/>
                                <circle cx="5.156" cy="5.156" r="5.156" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" data-name="Ellipse 118" transform="translate(41.715 289.793)"/>
                                <circle cx="5.156" cy="5.156" r="5.156" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" data-name="Ellipse 119" transform="translate(127.02 289.793)"/>
                                <circle cx="5.156" cy="5.156" r="5.156" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" data-name="Ellipse 120" transform="translate(99.84 289.793)"/>
                                <path stroke="#000" stroke-miterlimit="10" stroke-width="2.136" d="M72.418 262.946h7.031v17.714h-7.031z" data-name="Rectangle 984"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3.715" d="M72.418 278.18l-48.188 6.425a5.231 5.231 0 00-4.539 5.188h0" data-name="Path 243"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3.715" d="M79.449 278.18l48.188 6.425a5.231 5.231 0 014.539 5.188h0" data-name="Path 244"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3.715" d="M75.934 278.18l-24.347 7.264a6.6 6.6 0 00-4.713 6.33h0" data-name="Path 245"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3.715" d="M75.934 278.18l24.346 7.264a6.6 6.6 0 014.716 6.328h0" data-name="Path 246"/>
                            </g>
                            <g stroke="#000" stroke-miterlimit="10">
                                <path fill="#fff" stroke-width="2.3" d="M138.999 148.301h336.107v19.182H138.999z" data-name="Rectangle 985"/>
                                <path stroke-width="2.136" d="M138.999 168.309h336.107v9.591H138.999z" data-name="Rectangle 986"/>
                                <path fill="#fff" stroke-width="2.3" d="M138.999 177.9h87.076v144.481h-87.076z" data-name="Rectangle 987"/>
                                <path stroke-width="2.136" d="M464.555 322.381h-11.249V167.484h11.249z" data-name="Rectangle 988"/>
                            </g>
                            <g stroke="#000" stroke-miterlimit="10" stroke-width="2.136">
                                <path fill="#fff" d="M262.27 68.722h105.928v68.259H262.27z" data-name="Rectangle 989"/>
                                <path d="M262.27 136.981h105.928v11.32H262.27z" data-name="Rectangle 990"/>
                            </g>
                            <path d="M412.138 123.316a6.937 6.937 0 00-6.487-1.842v-6.146h-22.5v32.974h22.5v-6.636a4.468 4.468 0 00.689.072c2.227 0 5.413-1.609 7.242-9.768.944-3.423.405-6.656-1.444-8.654zm-1.316 7.924l-.017.072c-.881 3.945-2.627 7.983-4.794 7.546a3.626 3.626 0 01-.36-.122v-14.344c.091-.025.18-.058.272-.081a7.114 7.114 0 011.358-.141 3.582 3.582 0 012.761 1.084c1.162 1.253 1.46 3.548.782 5.986z"/>
                            <g stroke="#000" stroke-miterlimit="10" transform="translate(138.983 40.896)">
                                <circle cx="5.34" cy="5.34" r="5.34" stroke-width="2.863" data-name="Ellipse 121" transform="translate(0 55.267)"/>
                                <path fill="#fff" stroke-width="2.863" d="M5.34 55.267l19.002-30.624" data-name="Line 146"/>
                                <path fill="#fff" stroke-width="2.863" d="M7.725 65.379l19.287 30.406" data-name="Line 147"/>
                                <path stroke-width="2.136" d="M10.679 103.009h32.508v4.397H10.679z" data-name="Rectangle 991"/>
                                <path stroke-width="2.136" d="M21.892 94.528h11.024a3.047 3.047 0 013.047 3.047v5.433h0-17.118 0v-5.433a3.047 3.047 0 013.047-3.047z" data-name="Rectangle 992"/>
                                <path fill="#fff" stroke-width="2.136" d="M56.001 14.182L34.324 35.859l-9.9-9.9a14.65 14.65 0 01-2.04-18.135 2.256 2.256 0 01-.27-.24 3.988 3.988 0 015.61-5.611 2.171 2.171 0 01.241.27A14.652 14.652 0 0146.1 4.283z" data-name="Path 247"/>
                            </g>
                            <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" d="M250.079 147.927h-50.147l-4.228-32.221h50.152z" data-name="Path 248"/>
                            <g data-name="Group 195">
                                <path d="M225.325 131.814h-6.833q-.751 8.055-1.5 16.111h9.833l-1.5-16.111z" data-name="Path 249"/>
                                <path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2.136" d="M225.325 131.814h-6.833q-.751 8.055-1.5 16.111h9.833l-1.5-16.111z" data-name="Path 250"/>
                            </g>
                            <g>
                                <path stroke="#000" stroke-miterlimit="10" d="M25.416 286.879h58.667v17.832a20.018 20.018 0 01-20.018 20.018H45.434a20.018 20.018 0 01-20.018-20.018v-17.832h0z" data-name="Rectangle 993"/>
                                <path fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.3" d="M54.749 265.936c35.832 0 48.2-22.71 48.2-33.056s-16.4-31.794-16.4-46.934 23.709-31.037 23.709-50.718-27-25.99-36.589-43.149 3.28-63.082-21.952-67.625-29.771 32.048-39.612 63.84-13.121 54-2.523 66.364 28.054 28.021 24.976 38.606c-4.543 15.645-31.278 6.812-33.3 28.26s29.268 44.412 53.491 44.412z" data-name="Path 251"/>
                                <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.3" d="M54.749 286.879V118.826" data-name="Line 148"/>
                                <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.3" d="M54.749 167.273s7.066-17.411 22.458-23.466" data-name="Path 252"/>
                                <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.3" d="M54.749 235.15s-5.8-12.364-15.392-15.645" data-name="Path 253"/>
                                <path fill="#fff" d="M25.416 292.178h58.667v2.839H25.416z" data-name="Rectangle 994"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="es-message font-frank mt-8 text-xl font-700 mx-auto">
                It looks like you do not have a website setup.  Please
                <span @click="$router.push({ name:'websites.create',  query: { propertyId: community.id }})">setup a new website.</span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Auth from "@/mixins/Auth";
import AuthMixin from "@/components/auth/AuthMixin";
import {mapActions, mapGetters} from "vuex";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from '@/mixins/ModalNavigation';
import ModalLink from "@/components/ui/modals/ModalLink";
import SearchPassFlowTo from "@/mixins/SearchPassFlowTo";

export default {
    mixins: [ ModalNavigation, Auth, NotifyMixin, SearchPassFlowTo, AuthMixin],
    components: { ModalLink, Icon },
    data: () => {
        return {
            passFlowTo: 'websites.show',
            prevRoute: null,
        }
    },
    computed: {
        ...mapGetters({
            allWebsites: 'websites/all',
        }),
        filteredWebsites() {
            return this.allWebsites.filter( website => {
                if (! this.community) {
                    return;
                } else {
                    return website.communities_id === this.community.id;
                }
            });
        }
    },
    methods: {
        ...mapActions({
            loadWebsitesInStore: 'websites/loadWebsites',
        }),
        fetchWebsites() {
            this.$cmsDataProvider.getList('websites').then( response => {
                this.loadWebsitesInStore(response);
            })
                .catch((err) => this.notifyError(err));
        },
        go(routeName, params) {
            this.$router.push({ name: routeName, params })
        },
    },
    mounted() {
        if (this.customerId) {
            this.fetchWebsites();
            this.passFlowTo = this.setPassFlowTo('websites.show')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    watch: {
        allWebsites() {
            if (this.filteredWebsites.length === 1 && (this.prevRoute.name === 'home' || this.prevRoute.name === 'bookmarks')) {
                this.$router.push( {
                    name: this.passFlowTo,
                    params: {
                        id: this.filteredWebsites[0].id
                    }
                });
            }
        }
    }

}
</script>

<style scoped>
.property-row:last-child {
    border-bottom: 0; /** last: tailwind variant somehow not working :( */
}
.es-ill svg{
    margin: 0 auto;
    width: 75%;
}
@media (min-width: 1200px){
    .es-ill svg{
        width: 50%;
        max-width: 650px;
    }
}
.es-message{
    text-align: center;
    max-width: 50ch;
}
.es-message span{
    @apply block text-blue-500 border border-blue-500 py-4 px-6 mt-6 mx-auto text-lg;
    width: fit-content;
}
.es-message span:hover{
    background: #3598DB;
    color: #fff;
    cursor:pointer;
}
</style>